var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-seal-container" }, [
    _c("div", { staticClass: "seals-wrapper" }, [
      _vm._m(0),
      _c(
        "div",
        {
          ref: "sealsDrableArea",
          staticClass: "seals-drag-area",
          staticStyle: { overflow: "hidden" },
        },
        _vm._l(_vm.userSeals, function (elem, elemIndex) {
          return _c("div", { key: elemIndex, staticClass: "seals-drag-item" }, [
            _c("img", {
              attrs: { src: elem.src, draggable: "true" },
              on: {
                dragstart: function ($event) {
                  return _vm.dragStart($event, elemIndex, "add")
                },
              },
            }),
            _c("span", { attrs: { title: elem.sealName } }, [
              _vm._v(_vm._s(elem.sealName)),
            ]),
          ])
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "pdf-drag-wrapper" }, [
      _c("div", { staticClass: "pdf-tools" }, [
        _c("div", { staticClass: "left-btns" }, [
          _c(
            "span",
            {
              staticClass: "page-btn",
              class: { "page-btn--disabled": _vm.currentPage == 1 },
              on: { click: _vm.prevPage },
            },
            [_vm._v(_vm._s(_vm.$t("lang_previous_page")))]
          ),
          _c("span", { staticClass: "page-spliter" }, [_vm._v("|")]),
          _c(
            "span",
            {
              staticClass: "page-btn",
              class: { "page-btn--disabled": _vm.currentPage == _vm.pageCount },
              on: { click: _vm.nextPage },
            },
            [_vm._v(_vm._s(_vm.$t("lang_next_page")))]
          ),
        ]),
        _c("div", { staticClass: "right-btns" }, [
          _c(
            "span",
            { staticClass: "save-btn", on: { click: _vm.saveSeals } },
            [_vm._v(_vm._s(_vm.$t("lang_preservation")))]
          ),
          _c(
            "span",
            { staticClass: "save-btn", on: { click: _vm.goBackPath } },
            [_vm._v(_vm._s(_vm.$t("lang_shut_down")))]
          ),
        ]),
      ]),
      _c("div", { staticClass: "pdf-area" }, [
        _c(
          "div",
          {
            ref: "pdfAreaRef",
            staticClass: "pdf-dragable-area",
            on: { drop: _vm.handleSealAdd, dragover: _vm.allowDrop },
          },
          [
            _c("pdf", {
              ref: "pdfViewerRef",
              staticClass: "pdf-show-area",
              attrs: { src: _vm.fileUrl, page: _vm.currentPage },
              on: {
                "num-pages": function ($event) {
                  _vm.pageCount = $event
                },
                "page-loaded": _vm.loadPdfHandler,
              },
            }),
            _vm._l(_vm.fileSeals, function (elem, elemIndex) {
              return _c("img", {
                key: elemIndex,
                staticClass: "seal-moved",
                style: {
                  top: elem.topMargin + "px",
                  left: elem.leftMargin + "px",
                  width: _vm.sealWidth + "px",
                  display: elem.pageNo == _vm.currentPage ? "block" : "none",
                },
                attrs: { src: elem.src, draggable: "true" },
                on: {
                  dragstart: function ($event) {
                    return _vm.dragStart($event, elemIndex, "copy")
                  },
                  dragend: function ($event) {
                    return _vm.dragend($event, elemIndex)
                  },
                },
              })
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "seal-title" }, [
      _c("span", [_vm._v("个人签章")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }