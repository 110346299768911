<template>
  <div class="file-seal-container">
    <div class="seals-wrapper">
      <div class="seal-title">
        <span>个人签章</span>
      </div>

      <div class="seals-drag-area" ref="sealsDrableArea" style="overflow: hidden">
        <div v-for="(elem, elemIndex) in userSeals" :key="elemIndex" class="seals-drag-item">
          <img :src="elem.src" draggable="true" @dragstart="dragStart($event, elemIndex, 'add')" />
          <span :title="elem.sealName">{{ elem.sealName }}</span>
        </div>
      </div>
    </div>
    <div class="pdf-drag-wrapper">
      <div class="pdf-tools">
        <div class="left-btns">
          <span @click="prevPage" class="page-btn" :class="{ 'page-btn--disabled': currentPage == 1 }">{{ $t('lang_previous_page') }}</span>
          <span class="page-spliter">|</span>
          <span @click="nextPage" class="page-btn" :class="{ 'page-btn--disabled': currentPage == pageCount }">{{
            $t('lang_next_page')
          }}</span>
        </div>
        <div class="right-btns">
          <span class="save-btn" @click="saveSeals">{{ $t('lang_preservation') }}</span>
          <span class="save-btn" @click="goBackPath">{{ $t('lang_shut_down') }}</span>
        </div>
      </div>

      <div class="pdf-area">
        <div class="pdf-dragable-area" ref="pdfAreaRef" @drop="handleSealAdd" @dragover="allowDrop">
          <pdf
            :src="fileUrl"
            ref="pdfViewerRef"
            :page="currentPage"
            class="pdf-show-area"
            @num-pages="pageCount = $event"
            @page-loaded="loadPdfHandler"
          ></pdf>
          <img
            v-for="(elem, elemIndex) in fileSeals"
            :key="elemIndex"
            :src="elem.src"
            class="seal-moved"
            draggable="true"
            @dragstart="dragStart($event, elemIndex, 'copy')"
            @dragend="dragend($event, elemIndex)"
            :style="{
              top: elem.topMargin + 'px',
              left: elem.leftMargin + 'px',
              width: sealWidth + 'px',
              display: elem.pageNo == currentPage ? 'block' : 'none'
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';

// import UserApi from '@/api/system/UserApi';
import { buildFormWidgetMap } from '@/components/form-design/utils';
import FileApi from '@/api/system/FileApi';
import { getFormConfig } from '@/api/form-design/FormApi';

export default {
  name: 'FileSeal',
  components: { pdf },
  computed: {
    token() {
      return StorageUtil.getSessionStorage('token');
    }
  },
  data() {
    return {
      userSeals: [],
      fileSeals: [],
      currentPage: 0,
      pageCount: 0,
      fileUrl: '',
      pdfViewerHeight: 1131,
      sourceInfo: {},
      sealWidth: 120
    };
  },
  props: {},
  methods: {
    findFileUrl() {
      this.currentPage = 1;
      let fileId = this.$route.query.fileId;
      if (fileId) {
        this.fileUrl = `/river-fs/file/view?fileId=${fileId}&token=${this.token}`;
      } else {
        this.fileUrl = null;
      }
    },
    // 获取可用签章
    findUserSeals() {
      // let temp = {
      //   sealId: '01251212325180000',
      //   sealName: '测试',
      //   topMargin: 10,
      //   leftMargin: 10,
      //   src: '/river-fs/file/view?fileId=01251212325180000&token=' + this.token,
      //   type: 'user'
      // };
      // this.userSeals.push(temp);

      let formId = this.$route.query.formId;
      let compCode = this.$route.query.compCode;
      getFormConfig({ id: formId }).then(res => {
        if (res.code == '1' && res.data) {
          let formWidgetMap = buildFormWidgetMap(res.data.container.components);
          let curComp = formWidgetMap[compCode];
          let signs = curComp.properties.signs;
          if (signs && signs.length) {
            FileApi.list({ ids: signs }).then(res => {
              if (res.code == '1' && res.data) {
                let userSeals = res.data.map(sealItem => {
                  return {
                    sealId: sealItem.id,
                    sealName: sealItem.fileName,
                    topMargin: 10,
                    leftMargin: 10,
                    src: '/river-fs/file/view?fileId=' + sealItem.id + '&token=' + this.token
                  };
                });
                this.userSeals = userSeals;
              }
            });
          }
        }
      });
    },
    // 获取历史盖章记录
    findFileSeals() {
      this.fileSeals = [
        {
          sealId: SystemUtil.uuid(),
          sealName: '文章正文',
          fileId: '01251214395720001',
          imageId: '01251212325180000',
          type: 'file',
          pageNo: 1,
          src: '/river-fs/file/view?fileId=01251212325180000&token=' + this.token,
          topMargin: 300,
          leftMargin: 600
        }
      ];
    },

    nextPage() {
      if (this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    // pdf加载时
    loadPdfHandler(event) {
      this.currentPage = event; // 加载的时候先加载第一页
      this.pdfViewerHeight = this.$refs.pdfViewerRef.$el.clientHeight;
    },

    dragStart(event, index, type) {
      this.sourceInfo.index = index;
      this.sourceInfo.offsetX = event.offsetX;
      this.sourceInfo.offsetY = event.offsetY;
      this.sourceInfo.width = event.currentTarget.clientWidth;
      this.sourceInfo.height = event.currentTarget.clientHeight;
      this.sourceInfo.type = type;
    },
    handleSealAdd(event) {
      event.preventDefault();
      let pdfDom = this.$refs.pdfAreaRef;
      let pdfDomWidth = pdfDom.clientWidth;
      let pdfDomHeight = pdfDom.clientHeight;
      let sealWidth = this.sealWidth;
      let sealHeight = this.sourceInfo.height * (sealWidth / this.sourceInfo.width);

      let moveToSelf = false;
      if (event.toElement.className == event.srcElement.className && event.toElement.className == 'seal-moved') {
        moveToSelf = true;
      }
      let offsetX = event.offsetX - this.sourceInfo.offsetX;
      if (moveToSelf) {
        offsetX = event.toElement.offsetLeft + offsetX;
      }
      if (offsetX < 0) {
        offsetX = 0;
      }
      if (offsetX > pdfDomWidth - sealWidth) {
        offsetX = pdfDomWidth - sealWidth;
      }

      let offsetY = event.offsetY - this.sourceInfo.offsetY;
      if (moveToSelf) {
        offsetY = event.toElement.offsetTop + offsetY;
      }
      if (offsetY < 0) {
        offsetY = 0;
      }
      if (offsetY > pdfDomHeight - sealHeight) {
        offsetY = pdfDomHeight - sealHeight;
      }

      if (this.sourceInfo.type == 'add') {
        let curIndex = this.sourceInfo.index;
        let curElem = Object.assign({}, this.userSeals[curIndex]);
        curElem.leftMargin = offsetX;
        curElem.topMargin = offsetY;
        curElem.pageNo = this.currentPage;
        this.fileSeals.push(curElem);
      } else {
        let curIndex = this.sourceInfo.index;
        let curElem = Object.assign({}, this.fileSeals[curIndex]);
        curElem.leftMargin = offsetX;
        curElem.topMargin = offsetY;
        curElem.pageNo = this.currentPage;
        this.fileSeals.splice(curIndex, 1, curElem);
      }
    },
    allowDrop(event) {
      event.preventDefault();
    },
    dragend(event, index) {
      if (event.dataTransfer.dropEffect == 'none') {
        this.fileSeals.splice(index, 1);
      }
    },
    // 保存签章
    saveSeals() {
      let seals = [];
      let fileId = this.$route.query.fileId;
      this.fileSeals.forEach(item => {
        let temp = {
          fileId: fileId,
          imageId: item.sealId,
          pageNo: item.pageNo,
          leftDistance: item.leftMargin / 800,
          topDistance: item.topMargin / this.pdfViewerHeight
        };
        seals.push(temp);
      });
      if (seals.length > 0) {
        FileApi.seal(seals).then(res => {
          if (res.code == '1') {
            this.closeWindow();
          } else {
            this.$Message.error(res.message);
          }
        });
      }
    },
    // 关闭窗口
    closeWindow() {
      this.widgetModels = {};
      let userAgent = navigator.userAgent;
      if (userAgent.indexOf('Firefox') != -1 || userAgent.indexOf('Chrome') != -1) {
        window.open(' ', '_self'); //空格莫忘
        window.close();
      } else if (userAgent.indexOf('Android') > -1 || userAgent.indexOf('Linux') > -1) {
        window.opener = null;
        window.open('about:blank', '_self', '').close();
      } else {
        window.pener = null;
        window.open('about:blank', '_self');
        window.close();
      }
    },

    goBackPath() {
      if (window.history.length > 1) {
        this.$router.back();
      } else {
        this.$router.push('/');
      }
    }
  },
  created() {
    this.findFileUrl();
    this.findUserSeals();
    // this.findFileSeals();
  },
  mounted() {
    window.addEventListener('scroll', this.scrollFn, true);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollFn); // 销毁监听
  }
};
</script>

<style lang="scss" scoped>
.file-seal-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  .seals-wrapper {
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .seal-title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #ddd;
      padding: 0px 10px;
    }
    .seals-drag-area {
      display: flex;
      width: 100%;
      height: calc(100% - 40px);
      justify-content: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      padding: 20px 10px;
      box-sizing: border-box;
      .seals-drag-item {
        display: flex;
        flex-direction: column;
        width: 80px;
        justify-content: flex-start;
        margin-bottom: 10px;
        margin-left: 10px;
        img {
          width: 80px;
          height: 65px;
        }
        span {
          display: block;
          height: 20px;
          line-height: 20px;
          text-align: center;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .pdf-drag-wrapper {
    width: calc(100% - 300px);
    height: 100%;
    background: #333;
    padding-bottom: 10px;
    box-sizing: border-box;
    .pdf-tools {
      position: relative;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding: 10px 10px;
      border-bottom: 1px solid #ddd;
      box-sizing: border-box;
      .left-btns {
        display: flex;
        justify-content: flex-start;
        width: 50%;
        .page-btn {
          background: #000;
          padding: 2px 10px;
          border-radius: 4px;
          cursor: pointer;
          color: #fff;
          margin-left: 10px;
          &--disabled {
            cursor: not-allowed;
          }
        }
        .page-spliter {
          margin-left: 10px;
          color: #fff;
        }
        &:first-of-type(1) {
          margin-left: 10px;
        }
      }

      .right-btns {
        display: flex;
        justify-content: flex-end;
        width: 50%;
        .save-btn {
          background: #000;
          padding: 2px 10px;
          border-radius: 4px;
          cursor: pointer;
          color: #fff;
          &--disabled {
            cursor: not-allowed;
          }
        }
      }
    }

    .pdf-area {
      position: relative;
      width: 100%;
      height: calc(100% - 60px);
      margin-top: 10px;
      overflow-y: scroll;
      .pdf-dragable-area {
        position: relative;
        width: 800px;
        max-height: 1131px;
        margin: 0 auto;
        .pdf-show-area {
          width: 100%;
          // height: 100%;
        }
        .seal-moved {
          width: 120px;
          position: absolute;
          z-index: 999;
        }
      }
    }
  }

  /deep/.ghost {
    display: none;
  }
}
</style>
